<template lang="pug">
  .container
    .card.mt-2
      div(v-if='paymentModal')
        pin-confirm(:previewDetails='previewDetails' :pinVerification='verifyPin')
      .card-body(v-if='course')
        .row
          .col
            h3 {{course.title}}
        .row.mt-2
          .col(v-html="course.short_description")
        .row.pt-2
          .col-md-6
            h5 About this Course
            div(v-html='course.full_description')
            div.border-top(v-if="course.outcome")
              h5.mt-2 Outcome
              div(v-html='course.outcome' )
            div.border-top(v-if="course.prerequisites_raw")
              h5.mt-2 Prerequisites
              div.font-weight-light(v-html='course.prerequisites_raw')
          .col-md-6.border-left
            .row.col.pt-4.mb-2
              .col-12
                h5 Course Run Details
            .row.col(v-for='run in course.course_runs' :key='run.key' v-if='run.status === "published"')
              .col-6 Effort
              .col-6.font-weight-bold {{run.min_effort}} - {{run.max_effort}} Weeks
              .col-6 Weeks To Complete
              .col-6.font-weight-bold {{run.weeks_to_complete}}
              .col-6 Level
              .col-6.font-weight-bold {{run.level_type}}
              .col-6 Language
              .col-6.font-weight-bold {{run.content_language}}
              .col-6 Transcript Language
              .col-6.font-weight-bold {{run.transcript_languages.toString()}}
              .col-6 Start Date
              .col-6.font-weight-bold {{run.start.split("T")[0]}}
              .col-6 Price
              .col-6.font-weight-bold NPR {{run.amountInNPR}}
              .col-12.mt-3
                b-button.btn.btn-primary.btn-sm(v-b-modal.payment @click='purchaseCourse.courseRunsKey = run.key' :disabled='!run.amountInNPR') Get Code
            b-modal#payment(hide-header hide-footer).m-2
              h5 Purchase EDX code
              form(@submit.prevent="confirmPayment")
                .form-group
                  label Enter your email
                  input.form-control(v-model='purchaseCourse.email' type='email' required)
                .show-message(v-if='message') {{message}}
                .text-center(v-else)
                  .spinner-border.spinner-border-sm(v-if='loading')
                  show-bank(:banks='banks' :bankClicked='confirmBank' v-else)
      .card-body.text-center(v-else).p-5
        .card-loader
</template>

<script>
import ShowBank from "@/components/ShowBank";
import api from "@/helpers/api";
import PinConfirm from "@/components/PinConfirm.vue";
import payment from "@/mixins/payment";
import axios from "@/axios"
export default {
  mixins: [payment],
  computed: {
    previewDetails() {
      return {
        Email: this.purchaseCourse.email,
        "Total Amount to Pay": "Rs. " + this.purchaseCourse.amount
      };
    }
  },
  data() {
    return {
      id: "",
      course: null,
      banks: null,
      purchaseCourse: {
        servicesId: "",
        amount: 1,
        bank: "",
        courseKey: "",
        email: "",
        courseRunsKey: ""
      },
      loading: false,
      message: ""
    };
  },
  components: {
    ShowBank,
    PinConfirm
  },
  async mounted() {
    this.purchaseCourse.servicesId = await api.serviceIdBySlug("EDX");
    this.banks = await api.banks();
    this.id = this.$route.params.id;
    const res = await axios.get("edx/getCourseDetails", {
      params: {
        courseKey: this.id
      }
    });
    if (res.data && res.data.data && res.data.data.course_details) {
      this.course = res.data.data.course_details;
      this.course.course_runs = await Promise.all(this.course.course_runs.map(async run => {
        const priceRes = await axios.post("edx/getPrice", {
          amount: run.first_enrollable_paid_seat_price
        })
        run.amountInNPR = priceRes.data.data.amount
        return run
      }))
      this.purchaseCourse.courseKey = this.course.key;
    }
  },
  methods: {
    verifyPin(verified) {
      this.pinVerification(this.confirmPayment, verified);
    },
    confirmBank(bank) {
      this.purchaseCourse.bank = bank;
    },
    async confirmPayment() {
      if (this.pinVerified) {
        this.loading = true;
        const res = await axios.post("edx", this.purchaseCourse);
        this.loading = false;
        if(res.data && res.data.code === 1){
          this.message = res.data.data.message
          this.$store.dispatch("UPDATE_BALANCE")
        }
      } else {
        this.paymentModal = true;
      }
    }
  }
};
</script>
